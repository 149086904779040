import React, { useState } from "react";
//@ts-ignore
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css";
import { makeStyles } from "@material-ui/core/styles";

interface CalendarInterface {
  show: boolean;
  update: any;
}
export default function Calendar({ show, update }: CalendarInterface) {
  const useStyles = makeStyles({
    root: {
      visibility: () => (show ? "visible" : "hidden"),
      width: "120px",
      position: "absolute",
      zIndex: 1,
      "& .rdrCalendarWrapper": {
        background: "#1D1D25",
        border: "1px solid #464659",
        color: "#fcfcfc",
      },
      "& .rdrWeekDay": {
        color: "#fcfcfc",
      },
      "& .rdrDayNumber span": {
        color: "#fcfcfc",
      },
      "& .rdrDayPassive .rdrDayNumber span": {
        color: "#1D1D25",
      },
    },
  });
  const classes = useStyles();

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const print = (item: any) => {
    setState([item.selection]);
    update(item.selection);
  };

  return (
    <div className={classes.root}>
      <DateRange
        clearIcon
        editableDateInputs={true}
        onChange={print}
        moveRangeOnFirstSelection={false}
        ranges={state}
        showMonthAndYearPickers={false}
        showDateDisplay={false}
        rangeColors={["#464659"]}
        maxDate={new Date()}
        onCalendarClose={true}
      />
    </div>
  );
}
