import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Line } from "react-chartjs-2";
import { IconButton } from "@material-ui/core";
import Calendar from "./Calendar/Calendar";
import { format } from "date-fns";
import DateRangeIcon from "@material-ui/icons/DateRange";
import Grid from '@material-ui/core/Grid';
const disp = "inline-block"

const useStyles = makeStyles({
  root: {
    gridArea: "error",
    display: "grid",
    color: "#FCFCFC",
    background: "#2B2B36",
    width: "1186px"
  },
  content: {
    padding: "10px 20px 10px 20px",
  },
  header: {
    flexGrow: 1,
    fontSize: "18px",
  },
  title: {
    display: disp,
    fontSize: "18px",
    textAlign: "center",
    padding: "10px 10px 20px 10px",
    margin: "0px"
  },
  change: {
    background: "#D72B3F",
    borderRadius: "2px",
    padding: "2px 10px",
    marginLeft: "10px",
    fontSize: "12px",
    lineHeight: "14px",
  },
  date: {
    padding: "10px 10px 20px 10px",
    textAlign: "center"
  },
  label: {
    display: disp,
    fontSize: "18px",
    margin: "0px 10px 0px 0px",
  },
  arrow: {
    marginLeft: "10px",
    color: "#0089FF",
    float: "right",
    padding: "0px",
  },
  calendarBox: {
    display: disp,
    border: "1px solid #4F4F64",
    borderRadius: "2px",
    lineHeight: "16px",
    fontSize: "14px",
    width: "264px",
    height: "40px",
    padding: "10px",
    textAlign: "left"
  },
  datePicker: {
    position: "relative",
    left: "390px",
  },
});

export default function ErrorStatsCard({ data }: any) {
  const classes = useStyles();
  const date_value = data.map((obj: any) => {
    return {
      x: obj.date,
      y: obj.count
    }
  })
  const data1 = {
    datasets: [
      {
        fill: false,
        borderColor: "#0089FF",
        pointBackgroundColor: "#0089FF",
        data: date_value
      },
    ],
  };

  const options: any = {
    legend: {
      display: false,
    },
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          gridLines: false,
          type: "time",
          offset: true,
          bounds: "data",
          time: {
            padding: 5,
            unit: "day",
            displayFormats: {
              day: "D - MMM",
            },
          },
          ticks: {
            padding: 10,
            fontColor: "#FCFCFC",
            fontFamily: "Avenir Next",
          },
        },
      ],
      yAxes: [
        {
          padding: 2,
          ticks: {
            padding: 5,
            fontColor: "#FCFCFC",
            fontFamily: "Avenir Next",
            bounds: "data",
            stepSize: 2,
          },
        },
      ],
    },
  };

  const [dateString, setDateString] = useState("Select date range");

  const [showCalendar, setShowCalendar] = useState(false);
  let dates = {
    startDate: new Date(),
    endDate: null,
  };
  function showCalendarToggle() {
    setShowCalendar(!showCalendar);
  }
  function updateDates(item: any) {
    dates = { ...item };
    
    if (dates.endDate !== dates.startDate) {
      setTimeout(function () {
        showCalendarToggle();
      }, 300);
    }
    updateDateString(dates);
  }

  function updateDateString(newDates: any) {
    if (dates.endDate != null && dates.startDate != null) {
      const newDateString =
        format(newDates.startDate, "MMM dd") +
        " to " +
        format(newDates.endDate, "MMM dd");
      setDateString(newDateString);
    }
  }

  return (
    <div>
      <Card className={classes.root} variant="outlined">
        <CardContent className={classes.content}>
          <IconButton className={classes.arrow}>
          </IconButton>
          <div className={classes.header}>
            <Grid container>
              <Grid item xs={1}>
                <h6 className={classes.title}>Error</h6>
              </Grid>
              <Grid item xs={10}>
                <div className={classes.date}>
                  <div>
                    <h6 className={classes.label}>Date Range:</h6>
                    <div className={classes.calendarBox} onClick={showCalendarToggle}>
                      <span>
                        {dateString}
                        <DateRangeIcon
                          viewBox="0 3 24 24"
                          style={{ float: "right" }}
                        />
                      </span>
                    </div>
                  </div>
                  <div className={classes.datePicker}>
                    <Calendar show={showCalendar} update={updateDates} />
                  </div>
                </div>
              </Grid>
            </Grid>

          </div>

          <div style={{ height: "300px" }}>
            <Line data={data1} options={options} />
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
