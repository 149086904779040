import React, { useContext, useState, useEffect } from "react";
import useApi from "../hooks/useApi";
import LoadingIndicator from "../components/UiComponents/LoadingIndicator";

const ApplicationContext = React.createContext<IApplicationContextValues>({
  applications: null,
  selectedApplication: null,
  setSelectedApplicationId: () => true,
});

interface IApplicationProviderProps {
  children: React.ReactNode;
}

const ApplicationContextProvider: React.FC<IApplicationProviderProps> = ({
  children,
}) => {
  const [selectedApplicationId, setSelectedApplicationId] = useState("");
  const [applications, setApplcations] = useState<[]>([]);

  const { data } = useApi("/applications", {
    method: "GET",
  });

  const getSelectedApplication = () => {
    return (
      applications.find(
        ({ application_id }) => application_id === +selectedApplicationId
      ) || null
    );
  };

  useEffect(() => {
    if (data?.applications?.length > 0 && applications.length === 0) {
      setApplcations(data.applications);
      setSelectedApplicationId(data.applications[0].application_id);
    }
  }, [data]);

  return (
    <ApplicationContext.Provider
      value={{
        applications,
        selectedApplication: getSelectedApplication(),
        setSelectedApplicationId,
      }}
    >
      {applications.length > 0 ? children : <LoadingIndicator />}
    </ApplicationContext.Provider>
  );
};

interface IApplicationContextValues {
  applications: [] | null;
  selectedApplication: any;
  setSelectedApplicationId: (application: string) => void;
}

const useApplicationContext = () =>
  useContext<IApplicationContextValues>(ApplicationContext);

export { ApplicationContextProvider, useApplicationContext };
