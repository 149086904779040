import React, { useEffect } from "react";
import { useSnackbar } from "notistack";
import { LoadingIndicator, Modal } from "../../../../UiComponents";
import useApi from "../../../../../hooks/useApi";
import DeviceHistoryCard from "./DeviceHistoryCard";
import useStyles from "./DeviceHistory.styles";
import { useApplicationContext } from "../../../../../contexts/ApplicationContext";
import { Typography } from "@material-ui/core";

const DeviceHistory: React.FC<any> = ({ record, onDone }) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { selectedApplication } = useApplicationContext();
  const { data, status } = useApi(
    `/applications/${selectedApplication.application_id}/devices/${record.device_dlm_id}/history`,
    {
      method: "GET",
    }
  );

  useEffect(() => {
    if (status.error) {
      enqueueSnackbar("An error occured while reading history", {
        variant: "error",
      });
      onDone();
    }
  }, [status.error]);

  if (status.pending || !data) {
    return <LoadingIndicator />;
  }

  return (
    <Modal
      open={true}
      onClose={onDone}
      title={`Device History: ${data.device_id}`}
    >
      <div className={classes.deviceHistory}>
        {(data.device_history || []).length === 0 && (
          <Typography style={{ marginTop: "1em" }}>
            No Device Replacement History Found!
          </Typography>
        )}
        {data.device_history.map((deviceHistory: any) => (
          <DeviceHistoryCard
            key={deviceHistory.device_id}
            deviceHistoryEntry={deviceHistory}
          />
        ))}
      </div>
    </Modal>
  );
};

export default DeviceHistory;
