import React, { useEffect } from "react";
import { useSnackbar } from "notistack";
import {
  CustomButton,
  LoadingIndicator,
  SubmitButton,
} from "../../../../UiComponents";
import {
  TAG_TYPE,
  letterNumberRegXWithSpace,
} from "../../../../../utils/constant";
import useApi from "../../../../../hooks/useApi";
import useStyles from "./Submit.styles";
import readError from "../../../../../utils/readError";
import { hasDuplicateColumnEntries, isValidColumnFormat } from "../utils";

const MAX_TEXT_LIMIT = 50;

const hasDuplicateOptions = (optionsArray: string[]) => {
  const loweredOptions = optionsArray
    .filter((s) => !!s)
    .map((s) => s.toLowerCase());
  return new Set(loweredOptions).size !== loweredOptions.length;
};

const validateDropdownTag = (tagName: string, tagData: any) => {
  const optionsArray: string[] = tagData.options.split("|");

  if (tagData.data_type === "number") {
    if (optionsArray.some((op) => !op)) {
      throw new Error(
        `Invalid options given to tag '${tagName}'. Options should not be empty`
      );
    }
    if (optionsArray.some((op: string) => isNaN(+op))) {
      throw new Error(
        `Invalid options given to tag '${tagName}'. Data type is number.`
      );
    }
  }
  //validate new tags only
  if (tagData._isNew && hasDuplicateOptions(optionsArray)) {
    throw new Error(`Duplicate options entered for tag '${tagName}'.`);
  }
  if (
    tagData._isNew &&
    tagData.options &&
    !isValidOptionFormat(tagData.options)
  ) {
    throw new Error(`Invalid options entered for tag '${tagName}'.`);
  }
};

const checkForDuplicateColumnDeclaration = (tags: any): void => {
  const tabularTags = Object.keys(tags)
    .filter((tagName: string) => tags[tagName]?.type === TAG_TYPE.Tabular)
    .map((tagName: string) => ({ tagName, ...tags[tagName] }));

  const newlyAddedTabularTags = tabularTags.filter((tag: any) => tag._isNew);

  newlyAddedTabularTags.forEach((tag) => {
    const columnsInTag = tag.value.split("|");

    const columnNamesTaken = tabularTags
      .filter((t: any) => t.tagName !== tag.tagName)
      .map((t) => t.value?.split("|"))
      .flatMap((t) => t);

    columnsInTag.forEach((column: any) => {
      if (columnNamesTaken.some((cn) => cn === column)) {
        throw new Error(
          `Column name '${column}' is already in use.`
        );
      }
    });
  });
};

const isValidOptionFormat = (options: string) => {
  const optionsArray: string[] = options.split("|");
  return !optionsArray.some((op) => !op);
};

const hasOptionsRepeated = (options: string) => {
  const optionsArray: string[] = options.split("|");
  return new Set(optionsArray).size !== optionsArray.length;
};

const validateDropdownSpecsValues = (dropdownSpecs: any) => {
  const tabularTagsWithDropdownSpecs = Object.keys(dropdownSpecs);
  tabularTagsWithDropdownSpecs.forEach((tabularTag) => {
    const dropdownColumns = Object.keys(dropdownSpecs[tabularTag]);
    dropdownColumns.forEach((column) => {
      const options = dropdownSpecs[tabularTag][column];
      if (!isValidOptionFormat(options)) {
        throw new Error(
          `Invalid dropdown specification for ${tabularTag} tag (${column} column)`
        );
      }
      if (hasOptionsRepeated(options)) {
        throw new Error(
          `Options repeated in dropdwon specification for ${tabularTag} tag (${column} column)`
        );
      }
    });
  });
};

//TODO: this can be moved to utilities
const getMissingElements = (parentArray: string[], childArray: string[]) =>
  childArray.filter((val) => parentArray.indexOf(val) < 0);

const validateDropdownspecsMapping = (tags: any, dropdownSpecs: any) => {
  for (const dropdownSpecTagName in dropdownSpecs) {
    if (!tags[dropdownSpecTagName]) {
      throw new Error(
        `Tabular tag '${dropdownSpecTagName}' not found. Please remove dropdown specification for it`
      );
    }

    const mappedColumns = Object.keys(dropdownSpecs[dropdownSpecTagName]);
    const tabularTagColumns = (tags[dropdownSpecTagName]?.value || "").split(
      "|"
    );
    const missingColumns = getMissingElements(tabularTagColumns, mappedColumns);
    if (missingColumns.length > 0) {
      throw new Error(
        `Columns ${missingColumns.join(
          ","
        )} not found in tabular tag '${dropdownSpecTagName}'`
      );
    }
  }
};

const validateDropdownSpec = (tags: any, dropdownSpecs: any) => {
  validateDropdownspecsMapping(tags, dropdownSpecs);
  validateDropdownSpecsValues(dropdownSpecs);
};

const validateTextTag = (tagName: string, tagData: any) => {
  const isNumber = tagData.data_type === "number";

  if (isNumber) {
    if (!tagData.value) {
      throw new Error(
        `Tag default value cannot be empty for 'number' tags. Please enter zero for ${tagName}`
      );
    }
    if (isNaN(tagData.value)) {
      throw new Error(
        `Invalid value given to tag '${tagName}'. Data type is number.`
      );
    }
  }
};

const validateTabularTag = (tagName: string, tagData: any) => {
  if (!tagData._isNew) {
    return;
  }
  if (!tagData.value) {
    throw new Error(`Please enter columns for tabular tag '${tagName}'`);
  }
  if (!isValidColumnFormat(tagData.value)) {
    throw new Error(
      `Column entries are not valid for tabular tag '${tagName}'`
    );
  }
  if (hasDuplicateColumnEntries(tagData.value)) {
    throw new Error(`Duplicate columns found for tabular tag '${tagName}'`);
  }
};

const validateTags = (tags: any) => {
  for (const tagName in tags) {
    const tagData = tags[tagName];
    if (tagData.type === TAG_TYPE.Dropdown) {
      validateDropdownTag(tagName, tagData);
    } else if (tagData.type === TAG_TYPE.Text) {
      validateTextTag(tagName, tagData);
    } else if (tagData.type === TAG_TYPE.Tabular) {
      validateTabularTag(tagName, tagData);
    }
  }
};

const validateProperties = (properties: any) => {
  for (const property in properties) {
    const propertyValue = properties[property] || "";
    if (
      !letterNumberRegXWithSpace.test(propertyValue) ||
      propertyValue.length > MAX_TEXT_LIMIT
    ) {
      throw new Error(
        `Invalid value for property ${property} (only 0-9,A-Z,a-z,_,- allowed upto 50 characters)`
      );
    }
  }
};

const validateData = (configData: any) => {
  const { config_desc, tags, properties, dropdown } = configData;
  if (config_desc === "") {
    throw new Error("Please enter Configuration Description.");
  }
  if (
    !(letterNumberRegXWithSpace.test(config_desc) && config_desc?.length < 50)
  ) {
    throw new Error(
      "Invalid Configuration Description (only 0-9,A-Z,a-z,_,- allowed upto 50 characters)"
    );
  }
  validateTags(tags);
  checkForDuplicateColumnDeclaration(tags);
  validateDropdownSpec(tags, dropdown);
  validateProperties(properties);
};

const prepareTags = (tags: any) => {
  const updatedTags = {};
  for (const tag in tags) {
    //TODO: Restore the _isNew label. Backend needs it
    const { updatedVisibility, ...rest } = tags[tag];
    updatedTags[tag] = {
      ...rest,
      ...(updatedVisibility !== undefined
        ? { is_visible_as_column: updatedVisibility }
        : {}),
    };
  }

  return updatedTags;
};

const mapData = (configData: any) => {
  let mappedData: any = {
    device_config: {
      ...configData.existingData,
      tags: prepareTags(configData.updatedData.tags),
      properties: configData.updatedData.properties,
      config_name: configData.updatedData.fields.configurationName,
      config_desc: configData.updatedData.fields.configurationDescription,
      dropdown: configData.updatedData.dropdown,
    },
  };

  return mappedData;
};

const Submit: React.FC<any> = ({ configData, onDone }) => {
  const classes = useStyles();
  const { application_id, device_config_id } = configData.existingData;

  const { enqueueSnackbar } = useSnackbar();
  const {
    data: saveApiResponse,
    status: saveStatus,
    trigger: submitData,
  } = useApi(
    `/applications/${application_id}/device-configs/${device_config_id}`,
    {
      method: "PUT",
      deferred: true,
      includePMSCredentialsInRequestBody: true,
      onError: (error: any) => {
        const errorText =
          error?.response?.data?.data ||
          "An error occured while updating configuration";

        enqueueSnackbar(errorText, {
          variant: "error",
        });
      },
      doNotExtractError: true,
      // mock: { fetcher: () => ({ message: "success" }) },
    }
  );

  useEffect(() => {
    if ((saveApiResponse?.message || "").toLowerCase() === "success") {
      enqueueSnackbar("Configuration Changed", { variant: "success" });
      onDone();
    }
  }, [saveApiResponse, saveStatus.error]);

  return (
    <div className={classes.buttonWrapper}>
      {saveStatus.pending && <LoadingIndicator />}
      <CustomButton variant="outlined-white" onClick={onDone}>
        Cancel
      </CustomButton>
      <SubmitButton
        disabled={saveStatus.pending}
        onClick={() => {
          const data = mapData(configData);
          try {
            validateData(data.device_config);
          } catch (error) {
            const errorMessage = readError(error);
            return enqueueSnackbar(errorMessage, { variant: "error" });
          }
          submitData(data);
        }}
      >
        Save Configuration
      </SubmitButton>
    </div>
  );
};

export default Submit;
