import React, { useState, useEffect } from "react";
import { Typography, makeStyles, Button, withStyles, Grid, Divider, IconButton, MenuItem, Menu} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import exportLogo from "../../assets/Export.png";
import CreateExistingResource from "./CreateExistingResource";
import CreateNewResource from "./CreateNewResource";
import EditResource from "./EditResource";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import { exportToExcel } from "../../utils/helper";
import { Link } from "react-router-dom";
import { Backdrop, CircularProgress, TableSortLabel } from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import DefTableCell from "@material-ui/core/TableCell";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import EditIcon from "@material-ui/icons/Edit";
import ResourceDetails from "./ResourceDetails";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteResourcePrompt from "./DeleteResourcePrompt";
import AddResourcesPrompt from "./AddResourcesPrompt";
import { API } from "../../api/property";
import { SnackbarProvider, useSnackbar } from "notistack";
import TablePagination from "@material-ui/core/TablePagination";
import Pagination from "material-ui-flat-pagination";
import "../Users/Pagination.css";
import TextField from "@material-ui/core/TextField";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import CloseSharpIcon from "@material-ui/icons/CloseSharp";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import callXhrRequest from "../../utils/xhrRequestHandler";
import { arrayOfErrorsRefresh,arrayOfErrorsLogout } from '../../utils/helper';
import { useStore } from "mobx-store-provider";
import BreadcumbComponent from '../../utils/breadcurmb';
import FormatNumber from "../UiComponents/FormatNumber";
 
const useStyles = makeStyles((theme) => ({
  dialogRoot: {
    boxShadow: "0px 30px 100px #000000",
    borderRadius: "3px",
    maxWidth: "700px",
    minWidth: "700px",
    "& .dcTitle.dcHeader": {
      marginTop: "0px",
    },
    "& .dcTitle": {
      fontSize: "16px",
      lineHeight: "19px",
      color: "#FFFFFF",
      fontWeight: "bold",
      marginTop: "25px",
    },
    "& .dcOuter": {
      background: "#2B2B36",
      borderRadius: "4px",
      padding: "15px",
    },
    "& .dcFieldValue": {
      fontSize: "14px",
      lineHeight: "16px",
      marginTop: "10px",
      color: "#FFFFFF",
    },
    "& .dcSep": {
      marginTop: "20px",
    },
    "& .dcBtDelete": {
      borderRadius: "2px",
      border: "1px solid rgba(235,235,245,0.35)",
      color: "#0089FF",
      background: "none",
      right: "35em",
      bottom: "0.5em",
    },
    "& .dcBtEdit": {
      backgroundColor: "#0089ff",
      borderRadius: "4px",
      border: "none",
      color: "#FFFFFF",
      bottom: "0.5em",
      right: "0.7em",
    },
  },
  dialogRootSmall: {
    boxShadow: "0px 30px 100px #000000",
    borderRadius: "3px",
    maxWidth: "800px",
    position: "absolute",
    top: "10%",
    minWidth: "416px",
    "& .dcTitle.dcHeader": {
      marginTop: "0px",
    },
    "& .dcTitle": {
      fontSize: "16px",
      lineHeight: "19px",
      color: "#FFFFFF",
      fontWeight: "bold",
      marginTop: "25px",
    },
    "& .dcOuter": {
      background: "#2B2B36",
      borderRadius: "4px",
      padding: "15px",
    },
    "& .dcFieldValue": {
      fontSize: "14px",
      lineHeight: "16px",
      marginTop: "10px",
      color: "#FFFFFF",
    },
    "& .dcSep": {
      marginTop: "20px",
    },
    "& .dcBtDelete": {
      borderRadius: "2px",
      border: "1px solid rgba(235,235,245,0.35)",
      color: "#0089FF",
      background: "none",
    },
    "& .dcBtEdit": {
      backgroundColor: "#0089ff",
      borderRadius: "4px",
      border: "none",
      color: "#FFFFFF",
    },
  },
  formControl: {
    margin: "0 25px",
  },
  dividerColor: {
    background: "#464659",
    margin: "1em",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  header: {
    backgroundColor: "#2B2B36",
    color: "#FFFFFF",
  },
  headerItem: {
    display: "inline-block",
    marginRight: "0.5em",
  },
  headerRight: {
    padding: "0.5em",
    textAlign: "right",
  },
  headerLeft: {
    padding: "0.5em 0.5em",
  },
  tableSection: {
    padding: "30px",
    paddingTop: "11px"
  },
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  gridRoot: {
    flexGrow: 1,
    padding: "0.5em 24px",
    backgroundColor: "#2B2B36",
  },
  listItem: {
    width: "auto",
    padding: "0px",
    marginRight: "30px",
    fontSize: "16px",
    lineHeight: "19px",
    color: "#ffffff",
  },
  listItemText: {
    fontSize: "16px",
    lineHeight: "19px",
  },
  listButton: {
    padding: "0px",
    textTransform: "none",
    fontSize: "14px",
    whiteSpace: "nowrap",
  },
  cardTitle: {
    fontSize: "14px",
    fontWeight: "bold",
    lineHeight: "19px",
    color: "#FFFFFF",
    marginBottom: "16px",
  },
  cardText: {
    fontSize: "12px",
    lineHeight: "16px",
    color: "#FFFFFF",
    marginBottom: "10px",
  },
  CardStyle: {
    background: "#2B2B36",
    borderRadius: "3px",
  },
  cardGrid: {
    marginBottom: "20px",
  },
  dialogueTitle: {
    fontSize: "16px",
    fontWeight: "bold",
    display: "flex",
    marginTop: "5px",
    color: "#FFFFFF",
  },
  closeIcon: {
    color: "#FFFFFF",
    marginBottom: "20px",
    padding: "0px",
    fontSize: "15px"
  },
  dropdown: {
    width: "207px",
    height: "40px",
    background: "transparent",
    padding: "10px",
    color: "#FFFFFF",
    border: "1px solid #4CA5FF",
    font: "400 13.3333px 'Avenir Next'",
    borderRadius: "2px",
    marginRight: "30px",
    "& option": {
      background: "#1D1D25",
    },
  },
  label: {
    fontSize: "18px",
    lineHeight: "21px",
    marginRight: "18px",
    color: "#FFFFFF",
  },

  table: {
    padding: "30px",
    minWidth: 650,
    borderRadius: "0px",
  },
  tablecell: {
    color: "white",
  },
  tablehead: {
    borderBottom: "1px",
  },
  headercell: {
    color: "white",
    fontWeight: "bold",
    borderBottom: "none",
  },
  popover: {
    background: "#33333F",
    fontSize: "14px",
    color: "#FFFFFF",
    border: "1px solid #7C7C94",
    boxSizing: "border-box",
    borderRadius: "2px",
    width: "270px",
    height: "100%",
    padding: "10px",
    wordBreak: "break-all",
    whiteSpace: "normal",
  },
  menuitem: {
    color: "white",
    "&:hover": {
      color: "#0089FF !important",
    },
    "& svg": {
      marginRight: "8px",
    },
  },
  oddrow: {
    background: "#1D1D25",
    borderBottom: "none",
  },
  evenrow: {
    background: "#2B2B36",
  },
  viewButton: {
    textTransform: "none",
    color: "#0089FF",
    "&:hover": {
      cursor: "pointer",
    },
  },
  tblfooter: {
    position: "absolute",
  },
  tblfooter1: {
    position: "relative",
    padding: "10px 0",
    display: "flex",
    alignItems: "center",
    justify: 'space-between'
  },
  paginateView: {
    color: "#fff",
  },
  tblpaginate: {
    color: "#fff",
  },
  paginateUser: {
    color: "#fff",
  },
  tblpaginatenumber: {
    color: "#fff",
    marginRight: "13px"
  },
  labels: {
    color: "#fcfcfc",
    lineHeight: "16px",
    fontSize: "14px",
    transform: "scale(1)",
  },
  flexContainer: {
    display: "flex",
    flexDirection: "row",
  },
  listItem2: {
    width: "auto",
    padding: "0px",
    marginRight: "20px",
    fontSize: "16px",
    color: "#FFFFFF",
    lineHeight: "19px",
  },

}));

const TableCell = withStyles({
  root: {
    borderBottom: "none",
  },
})(DefTableCell);

const StyledTableSort = withStyles({
  root: {
    color: '#ffffff',
  },
  icon: {
    color: '#ffffff !important',
    fontSize: "26px"
  },
})(TableSortLabel)

const CssTextField = withStyles({
  root: {
    "& input": {
      padding: "10px",
      color: "#ffffff",
      backgroundColor: "#1D1D25 ",
      border: "1px solid #464659",
      borderRadius: "2px",
    },
    "& .MuiInput-multiline": {
      padding: "10px",
      color: "#ffffff",
      backgroundColor: "#1D1D25 ",
      border: "1px solid #464659",
      borderRadius: "2px",
      marginTop: "32px",
      marginLeft: "45px"
    },
    "& .MuiInput-underline": {
      "&&:before": {
        borderBottom: "none",
      },
      "&&:after": {
        borderBottom: "none",
      },
    },
    "& label.Mui-focused": {
      color: "#ffffff",
    },
  },
})(TextField);

const getFilteredItem = (items: any, search: string) => {
  const _search = search.toLowerCase();

  return items.filter((item: any) => {
    if (item.iot_hub_name && item.iot_hub_name.toLowerCase().includes(_search)) {
      return true
    }
    if (item.region && item.region.toLowerCase().includes(_search)) {
      return true
    }
    if (item.subscription_name && item.subscription_name.toLowerCase().includes(_search)) {
      return true
    }
    if (item.sku && item.sku.toLowerCase().includes(_search)) {
      return true
    }
    return false
  })
}

const getSortedItem = (sortBy: string, sortOrder: string, selectedApplication: any) => {
  const itemsToSort = JSON.parse(JSON.stringify(selectedApplication));
  const compareFn = (i: any, j: any) => {
    if (i[sortBy] < j[sortBy]) {
      return sortOrder === "asc" ? -1 : 1;
    } else {
      if (i[sortBy] > j[sortBy]) {
        return sortOrder === "asc" ? 1 : -1;
      } else {
        return 0;
      }
    }
  }
  return itemsToSort.sort(compareFn);
}

function Resources() {
  const API_KEY = window.SERVER_DATA.REACT_APP_PM_API_KEY;
  const { user } = useStore();

  const classes = useStyles();
  const [backDrop, setbackDrop] = useState(false);
  const backDropClose = () => {
    setbackDrop(false);
  };
  const [exportpopup, setExportPopupOpen] = useState(false);
  const dialogHandleClose = () => {
    setExportPopupOpen(false);
    setExportRadioBtn({
      application:true,
      resource_name: true,
      region_id: true,
      subscription_id: true,
      sku: true,
    })
  };
  const { enqueueSnackbar } = useSnackbar();
  const [reload, setReload] = useState(false);
  const tokenKey = "sso_token";
  const accessToken = JSON.parse(sessionStorage.getItem(tokenKey) as string);
  const [selectedResources, setSelectResource] = useState("");
  const [selectDeleteResources, setSelectDeleteResource] = useState("");
  const [selectDeleteApplication, setSelectDeleteApplication] = useState("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [application, setApplication] = useState([] as any)
  const [displayTable, setDisplayTable] = useState<boolean>(true);
  const [displayAddNewResource, setDisplayAddNewResource] = useState<boolean>(false);
  const [displayAddExistingResource,setDisplayAddExistingResource] = useState<boolean>(false);
  const [displayEditResource, setDisplayEditResource] = useState<boolean>(false);
  const [selectedApplication, setSelectedApplication] = useState([] as any);
  const [app, setApp] = useState("" as any);
  const [displayDeleteResource, setDisplayDeleteResource] = useState<boolean>(false);
  const [displayResourceDetails, setDisplayResourceDetails] = useState<boolean>(false);
  const [userApplicationData, setUserApplicationData] = useState([] as any);
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    row: any
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectResource(row);
    setSelectDeleteResource(row.iot_hub_dlm_id);
    setSelectDeleteApplication(row.application_id)
  };
  const handleClose = () => {
    setAnchorEl(null);
    setSelectResource("");
  };
  const toggleDisplayResourceDetails = () => {
    setDisplayResourceDetails(!displayResourceDetails);
    setAnchorEl(null);
  };
  const toggleDisplayDeleteResource = () => {
    setDisplayDeleteResource(!displayDeleteResource);
    setAnchorEl(null);
    setReload(!reload);
    setDisplayResourceDetails(false);
  };
  const toggleDisplayAddNewResource = () => {
    setDisplayAddNewResource(!displayAddNewResource);
    toggleDisplayTable();
    setDisplayResourcePrompt(false);
  };
  const toggleDisplayAddExistingResource = () => {
    setDisplayAddExistingResource(!displayAddExistingResource);
    toggleDisplayTable();
  };
  const [exportRadioBtn, setExportRadioBtn] = useState({
    application: true,
    resource_name: true,
    region_id: true,
    subscription_id: true,
    sku: true,
  });
  const handleChange = (event: any) => {
    setExportRadioBtn({
      ...exportRadioBtn,
      [event.target.name]: event.target.checked,
    });
  };
  const onExport = async () => {
    if (exportRadioBtn.resource_name === false && exportRadioBtn.region_id === false && exportRadioBtn.subscription_id === false && exportRadioBtn.sku === false) {
      enqueueSnackbar("Please select at least one column to export.", { variant: "error" });
    } else {
      setTimeout(() => {
        setExportPopupOpen(false);
      }, 100);
      const expUsersChecked = [] as any;
      for (const [key, value] of Object.entries(exportRadioBtn)) {
        if (value) {
          expUsersChecked.push(key);
        }
      }
      setbackDrop(true);
      const exclData = [] as any;
      userApplicationData.forEach((item: any) => {
        const row: any = {};
        if (exportRadioBtn.application) {
          row.Application = item.application_name;
        }
        if (exportRadioBtn.resource_name) {
          row.IoTHubName = item.iot_hub_name;
        }
        if (exportRadioBtn.region_id) {
          row.Region = item.region;
        }
        if (exportRadioBtn.subscription_id) {
          row.Subscription = item.subscription_name;
        }
        if (exportRadioBtn.sku) {
          row.SKU = item.sku;
        }
        if (Object.keys(row).length) {
          exclData.push(row);
        }
      });
      await exportToExcel(exclData, "Resources", exclData.length);
      setbackDrop(false);
      enqueueSnackbar("Export initiated.", { variant: "success" });
      setTimeout(() => {
        setReload(!reload);
        setExportRadioBtn({
          application:true,
          resource_name: true,
          region_id: true,
          subscription_id: true,
          sku: true,
        })
      }, 10);
    }
  };
  const toggleDisplayEditResource = () => {
    setAnchorEl(null);
    setDisplayEditResource(!displayEditResource);
    toggleDisplayTable();
    setReload(!reload);
  };
  const toggleDisplayTable = () => {
    setDisplayTable(!displayTable);
  };
  const [displayResourcePrompt, setDisplayResourcePrompt] = useState<boolean>(false);
  const toggleDisplayResourcePrompt = () => {
    setDisplayResourcePrompt(!displayResourcePrompt);
  };
  const toggleHideResourcePrompt = () => {
    setDisplayAddExistingResource(false);
    setReload(!reload);
    toggleDisplayResourcePrompt();
  };
  const iconClose = () => {
    setExportPopupOpen(false);
  }

  const onChangeApplication = (event: any) => {
    setApp(event.target.value);
    const temp = [...userApplicationData];

    setSelectedApplication(temp.filter(function filterIotHub(hubName: any) {
      return hubName.application_name === event.target.value
    }).sort(function compare_item(a: any, b: any) {
      if (a.iot_hub_name < b.iot_hub_name) {
        return -1;
      } else if (a.iot_hub_name > b.iot_hub_name) {
        return 1;
      } else {
        return 0;
      }
    }));
  }

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const [offset, setOffset] = React.useState(0);
  const handleClickPaginate = (offset: number) => {
    setOffset(offset);
    setPage(offset / rowsPerPage);
  };

  const [searchActive, setSearchActive] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const handleCloseSearch = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setSearchActive(false);
  };
  const handleChangeSearch = (event: any) => {
    const target = event.target.value;
    setSearch(target);
  };

  const [defaultSortBy, setDefaultSortBy] = useState("iot_hub_name");
  const [defaultSortOrder, setDefaultSortOrder] = useState("asc");

  const sortedItems = getSortedItem(defaultSortBy, defaultSortOrder, selectedApplication);
  const [sortedItemRows, setSortItemRows] = useState(sortedItems);

  useEffect(() => {
    let rows = selectedApplication;

    if (search.length) {
      setOffset(0);
      setPage(0);
      rows = getFilteredItem(selectedApplication, search)
    }

    const sortedItems = getSortedItem(defaultSortBy, defaultSortOrder, rows);
    setSortItemRows(sortedItems);
  }, [defaultSortBy, defaultSortOrder, selectedApplication, search])

  const sortByColumn = (columnName: any) => {
    if (columnName === defaultSortBy) {
      setDefaultSortOrder(defaultSortOrder === "asc" ? "desc" : "asc");
    } else {
      setDefaultSortOrder("asc");
      setDefaultSortBy(columnName)
    }
  }
  useEffect(() => {
    const tokenKey = "sso_token";
    const accessToken = JSON.parse(sessionStorage.getItem(tokenKey) as string);
    interface sObj {
      pm_s_id: string;
    }
    interface tObj {
      pm_s_token: string;
    }
    let pmSId: sObj = {
      pm_s_id: "",
    };
    let pmST: tObj = {
      pm_s_token: "",
    };
    pmSId =
      sessionStorage.getItem("pm_s_id") || JSON.parse(JSON.stringify(pmSId));
    pmST =
      sessionStorage.getItem("pm_s_token") || JSON.parse(JSON.stringify(pmST));
    setbackDrop(true);
    const APPLICATION_API = API["GETALLAPPLS"];
    const RESOURCE_API = API["GETRESOURCE"];

    Promise.all([fetch(`${APPLICATION_API}?pm_s_token=${pmST}&pm_s_id=${pmSId}`, {
      headers: {
        'Authorization': `Bearer ${accessToken.access_token}`,
        'Ocp-Apim-Subscription-Key': `${API_KEY}`,
        'Ocp-Apim-Trace': `true`
      },
    }), fetch(`${RESOURCE_API}?pm_s_token=${pmST}&pm_s_id=${pmSId}`, {
      headers: {
        'Authorization': `Bearer ${accessToken.access_token}`,
        'Ocp-Apim-Subscription-Key': `${API_KEY}`,
        'Ocp-Apim-Trace': `true`
      },
    })])
      .then(function (responses) {
        return Promise.all(
          responses.map(function (response) {
            return response.json();
          })
        );
      })
      .then(function (response) {
        setbackDrop(false);
        if ((response && response[0].status === 401) && (response && arrayOfErrorsRefresh.includes(response[0].message.toLowerCase()))) {
          try {
            const tokenKey = "sso_token";
            setbackDrop(true);
            callXhrRequest().then(function(data){
              sessionStorage.setItem(tokenKey, data);
              setbackDrop(false);
              setReload(!reload)
  
            }).catch(function(error){
              user.triggerLogout();
              console.log('error', error)
            })
          } catch (error) {
            console.log(error);
          }
          return;
        }
        else if ((response && response[0].status === 401) && (response && arrayOfErrorsLogout.includes(response[0].message.toLowerCase()))) {
          try {
            user.triggerLogout();
          } catch (error) {
            console.log(error);
          }
          return;
        }

        if (
          response &&
          response[0].status === 200 &&
          typeof response[0].applications !== "undefined"
        ) {
          setApplication(response && response[0].applications);
        }

        if (
          response &&
          response[0].status === 200 &&
          typeof response[1].az_iot_hubs !== "undefined"
        ) {
          setUserApplicationData(response && response[1].az_iot_hubs)
          setSelectedApplication(response[1].az_iot_hubs.filter(function filterIotHub(iotHubName: any) {
            return (
              iotHubName.application_name === response[0].applications[0].application_name
            );
          }))
        }


      })
      .catch((error) => {
        if (error) {
          return false;
        } else {
          return true;
        }

      });
  }, [reload, accessToken.access_token, enqueueSnackbar, API_KEY]);




  let pageView = (
    <div>
      <div style={{ marginLeft: "2em" }}>
        <label className={classes.label}>Applications: </label>
        <select
          className={classes.dropdown}
          onChange={onChangeApplication}
          value={app}
        >
          {Array.isArray(application) &&
            application.map((option: any) => (
              <option
                value={option.application_name}
                label={option.application_name}
              >
                {option.application_name}
              </option>
            ))}
        </select>
      </div>

      <div
        className={classes.gridRoot}
        style={{ marginLeft: "2.1em", marginRight: "2.1em", marginTop: "20px" }}
      >
        <Grid container direction="column" spacing={0}>
          <Grid
            container
            direction="row"
            xs={12}
            sm={6}
            alignItems="center"
            style={{ marginTop: "0.2em", marginBottom: "0.2em" }}
          >
            <BreadcumbComponent route='resources'/>
          </Grid>
          <Divider
            variant="fullWidth"
            style={{
              background: "#33333F",
              marginLeft: "-24px",
              marginRight: "-24px",
            }}
          />
          <Grid container direction="row">
            <Grid
              container
              spacing={0}
              className={classes.header}
              alignItems="center"
            >
              <Grid item xs={12} sm={4} className={classes.headerLeft}>
                <List classes={{ root: classes.flexContainer }} dense={true}>
                  <ListItem classes={{ root: classes.listItem2 }}>
                    <Typography
                      className={classes.headerItem}
                      style={{ marginLeft: "-20px", marginRight: "-3em" }}
                    >
                      <span>
                        Total IoT Hubs :{" "}
                        <strong style={{ color: "#FFFFFF", display: "contents" }}>
                          <FormatNumber number={selectedApplication.length} />
                        </strong>
                      </span>
                    </Typography>
                  </ListItem>
                  <ListItem classes={{ root: classes.listItem2 }}>
                    <Button
                      variant="text"
                      color="inherit"
                      className={classes.listButton}
                      startIcon={
                        <img
                          alt="Export Logo"
                          src={exportLogo}
                          width="20px"
                          height="20px"
                        />
                      }
                      onClick={() => setExportPopupOpen(true)}
                      style={{ left: "40px", paddingLeft: "4em" }}
                    >
                      Export
                    </Button>
                  </ListItem>

                  <ListItem classes={{ root: classes.listItem2 }}>
                    <ClickAwayListener onClickAway={handleCloseSearch}>
                      {searchActive || search !== "" ? (
                        <CssTextField
                          InputProps={{
                            disableUnderline: true,
                          }}
                          InputLabelProps={{
                            shrink: false,
                            className: classes.labels,
                          }}
                          autoComplete="off"
                          value={search}
                          onChange={(event) => handleChangeSearch(event)}
                          id="filled-search"
                          type="search"
                          variant="filled"
                          style={{ marginLeft: "40px" }}
                        />
                      ) : (
                        <Button
                          variant="text"
                          color="inherit"
                          className={classes.listButton}
                          startIcon={<SearchIcon style={{ color: "#7C7C94" }} />}
                          onClick={() => setSearchActive(true)}
                          style={{ marginLeft: "6em" }}
                        >
                          Search
                        </Button>
                      )}
                    </ClickAwayListener>
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} sm={8} className={classes.headerRight}>
                  <Button
                    style={{
                      background: "#0089FF",
                      textTransform: "none",
                      fontSize: "16px",
                    }}
                    variant="contained"
                    color="inherit"
                    endIcon={<AddIcon />}
                    size="large"
                    onClick={() => {
                      toggleDisplayResourcePrompt();
                    }}
                  >
                    Add New Resource
                  </Button>
              </Grid>
            </Grid>
          </Grid>
          <Backdrop
            className={classes.backdrop}
            open={backDrop}
            onClick={backDropClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <Dialog
            classes={{ paper: classes.dialogRoot }}
            onClose={dialogHandleClose}
            aria-labelledby="customized-dialog-title"
            open={exportpopup}
          >
            <Grid container direction="row" alignItems="center">
              <DialogTitle
                id="customized-dialog-title"
                style={{ color: "white" }}
              >
                Select Columns to Export
              </DialogTitle>
              <div>
                <IconButton style={{ marginTop: "20px", marginLeft: "22rem" }} color="primary" onClick={iconClose} >
                  <CloseSharpIcon className={classes.closeIcon} />
                </IconButton>
              </div>
            </Grid>


            <DialogContent>
              <FormControl component="fieldset" className={classes.formControl}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        style={{ color: "#0089ff" }}
                        color="primary"
                        checked={exportRadioBtn.resource_name}
                        onChange={handleChange}
                        name="resource_name"
                      />
                    }
                    style={{ color: "white" }}
                    label="IoT Hub Name"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        style={{ color: "#0089ff" }}
                        color="primary"
                        checked={exportRadioBtn.region_id}
                        onChange={handleChange}
                        name="region_id"
                      />
                    }
                    style={{ color: "white" }}
                    label="Region"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        style={{ color: "#0089ff" }}
                        color="primary"
                        checked={exportRadioBtn.subscription_id}
                        onChange={handleChange}
                        name="subscription_id"
                      />
                    }
                    style={{ color: "white" }}
                    label="Subscription"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        style={{ color: "#0089ff" }}
                        color="primary"
                        checked={exportRadioBtn.sku}
                        onChange={handleChange}
                        name="sku"
                      />
                    }
                    style={{ color: "white" }}
                    label="SKU"
                  />
                </FormGroup>
              </FormControl>

            </DialogContent>
            <Divider
              variant="middle"
              classes={{ middle: classes.dividerColor }}
            />
            <DialogActions>
              <Button
                onClick={dialogHandleClose}
                variant="outlined"
                className="dcBtDelete"
                style={{ textTransform: "none", paddingRight: "1.9em", paddingLeft: "1.6em", marginRight: "-2.5em" }}
              >
                Cancel
              </Button>
              <Button
                onClick={onExport}
                variant="contained"
                className="dcBtEdit"
                style={{ textTransform: "none", paddingLeft: "1.8em", paddingRight: "1.6em", marginRight: "1em" }}
              >
                Export
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </div>
      <Grid container className={classes.tableSection}>
        <TableContainer>
          <Table className={classes.table} size="small">
            <TableHead className={classes.tablehead}>
              <TableRow
                className={classes.headercell}
                style={{ background: "#464659" }}
              >
                <DefTableCell className={classes.headercell}>
                  <StyledTableSort
                    active={defaultSortBy === "iot_hub_name"}
                    direction={defaultSortOrder === 'asc' ? 'asc' : 'desc'}
                    onClick={() => sortByColumn("iot_hub_name")}
                    IconComponent={ArrowDropDown}
                    style={{ color: 'white' }}
                  >
                    IoT Hub Name

                  </StyledTableSort>
                </DefTableCell>
                <DefTableCell className={classes.headercell} align="left">
                  <StyledTableSort
                    active={defaultSortBy === "region"}
                    direction={defaultSortOrder === 'asc' ? 'asc' : 'desc'}
                    onClick={() => sortByColumn("region")}
                    style={{ color: "white" }}
                    IconComponent={ArrowDropDown}
                  >
                    Region
                  </StyledTableSort>
                </DefTableCell>
                <DefTableCell className={classes.headercell} align="left">
                  <StyledTableSort
                    active={defaultSortBy === "subscription_name"}
                    direction={defaultSortOrder === 'asc' ? 'asc' : 'desc'}
                    onClick={() => sortByColumn("subscription_name")}
                    style={{ color: "white" }}
                    IconComponent={ArrowDropDown}
                  >
                    Subscription
                  </StyledTableSort>
                </DefTableCell>
                <DefTableCell className={classes.headercell} align="left">
                  <StyledTableSort
                    active={defaultSortBy === "sku"}
                    direction={defaultSortOrder === 'asc' ? 'asc' : 'desc'}
                    onClick={() => sortByColumn("sku")}
                    style={{ color: "white" }}
                    IconComponent={ArrowDropDown}
                  >
                    SKU
                  </StyledTableSort>
                </DefTableCell>

                <DefTableCell
                  className={classes.headercell}
                  align="left"
                ></DefTableCell>
              </TableRow>
            </TableHead>
            {selectedApplication.length > 0 ? (
              <TableBody>
                {(sortedItemRows.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )).map((row: any, index: number) => (
                  <TableRow
                    className={
                      index % 2 === 0 ? classes.evenrow : classes.oddrow
                    }
                    key={row}
                  >
                    <TableCell
                      className={classes.tablecell}
                      component="th"
                      scope="row"
                    >
                      {row.iot_hub_name}
                    </TableCell>
                    <TableCell className={classes.tablecell} align="left">
                      {row.region}
                    </TableCell>
                    <TableCell className={classes.tablecell} align="left">
                      {row.subscription_name}
                    </TableCell>
                    <TableCell className={classes.tablecell} align="left">
                      {row.sku}
                    </TableCell>

                    <TableCell component="th" scope="row">
                      <div>
                        <IconButton
                          onClick={(event) => handleClick(event, row)}
                          style={{ marginRight: "-34px" }}
                        >
                          <MoreVertIcon color="secondary" />
                        </IconButton>
                        <Menu
                          id="resource-menu"
                          anchorEl={anchorEl}
                          keepMounted
                          open={Boolean(anchorEl)}
                          onClose={handleClose}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                        >
                          <MenuItem
                            className={classes.menuitem}
                            onClick={toggleDisplayResourceDetails}
                            data-id={row}
                          >
                            <VisibilityIcon fontSize="small"></VisibilityIcon>
                            View Details
                          </MenuItem>
                          <MenuItem
                            data-id={row}
                            className={classes.menuitem}
                            onClick={toggleDisplayEditResource}
                          >
                            <EditIcon></EditIcon>
                            Edit
                          </MenuItem>
                          <MenuItem
                            data-id={row}
                            className={classes.menuitem}
                            onClick={toggleDisplayDeleteResource}
                          >
                            <DeleteOutlineIcon></DeleteOutlineIcon>
                            Delete
                          </MenuItem>
                        </Menu>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : (
              <div style={{ color: "white", paddingTop: "1.2rem" }}>
                No Records Found.
              </div>
            )}
          </Table>


          <ResourceDetails
            displayResourceDetails={displayResourceDetails}
            toggleDisplayResourceDetails={toggleDisplayResourceDetails}
            toggleDisplayEditResource={toggleDisplayEditResource}
            selectedResources={selectedResources}
            toggleDisplayDeleteResource={toggleDisplayDeleteResource}

          />
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
              horizontal: "right",
              vertical: "top",
            }}
          >
            <DeleteResourcePrompt
              displayDeleteResource={displayDeleteResource}
              toggleDisplayDeleteResource={toggleDisplayDeleteResource}
              selectDeleteResources={selectDeleteResources}
              selectDeleteApplication={selectDeleteApplication}
            />

          </SnackbarProvider>
        </TableContainer>
      </Grid>
      <AddResourcesPrompt
        displayResourcePrompt={displayResourcePrompt}
        toggleDisplayResourcePrompt={toggleDisplayResourcePrompt}
        toggleDisplayAddNewResource={toggleDisplayAddNewResource}
        toggleDisplayAddExistingResource={toggleDisplayAddExistingResource}
      />
      <Grid container xs={12} direction='row' alignItems='center' justifyContent='space-between' style={{ paddingLeft: '2rem', marginTop: "-1.5rem" }}>
        <Grid xs={4} item container direction='row' alignItems='center'>
          <Typography className={classes.paginateView}>
            View:{" "}
          </Typography>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={sortedItemRows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            labelRowsPerPage={"User per page :"}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            className={classes.tblpaginate}
          />
          <Typography className={classes.paginateUser}>
            Resources per page
          </Typography>
        </Grid>
        <Pagination
          limit={rowsPerPage}
          offset={offset}
          onClick={(e, offset) => handleClickPaginate(offset)}
          total={sortedItemRows.length}
          className={classes.tblpaginatenumber}
          currentPageColor="secondary"
          otherPageColor="primary"
          style={{ marginRight: "17px" }}
        />
      </Grid>

    </div>
  );
  if (displayAddExistingResource) {
    pageView = (
      <div>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            horizontal: "right",
            vertical: "top",
          }}
        >
          <CreateExistingResource iotHubData={userApplicationData} onCancelAction={toggleHideResourcePrompt} />
        </SnackbarProvider>
      </div>
    );
  }else if (displayAddNewResource) {
    pageView = (
      <div>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            horizontal: "right",
            vertical: "top",
          }}
        >
         <CreateNewResource toggleDisplayAddNewResource={toggleDisplayAddNewResource}/> 
        </SnackbarProvider>
      </div>
    );
  } else if (displayEditResource) {
    pageView = (
      <div>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            horizontal: "right",
            vertical: "top",
          }}
        >
          <EditResource
            editResources={selectedResources} onCancelAction={toggleDisplayEditResource}/>
        </SnackbarProvider>
      </div>
    );
  }
  return pageView;
}

export default function Resourcess() {
  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        horizontal: "right",
        vertical: "top",
      }}
    >
      <Resources />
    </SnackbarProvider>
  );
}
