import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";

import { useSnackbar } from "notistack";
import {
  CustomButton,
  DropDown,
  SubmitButton,
  LinearProgressBar,
} from "../../../../UiComponents";
import useApi from "../../../../../hooks/useApi";
import { InputContextProvider } from "../../../../../contexts/InputContext";
import {
  ApplicationContextProvider,
  useApplicationContext,
} from "../../../../../contexts/ApplicationContext";
import useStyles from "./BulkUpdate.styles";
import {
  fileReader,
  sanitizeFileData,
  validateData,
  extractData,
  readError,
} from "./utils";
import FileUploader from "./FileUploader";
import DeviceConfigurationDropDown from "./DeviceConfigurationDropDown";
import BulkUpdateStatusChecker from "./BulkUpdateStatusChecker";
import SampleCSVDownloader from "./SampleCSVDownloader";

const BulkUpdate: React.FC<any> = ({ returnHome }) => {
  const { applications } = useApplicationContext();
  const [selectedApplicationId, setSelectedApplicationId] = useState<any>("");
  const [selectedFile, setSelectedFile] = useState<any>({});
  const [selectedDeviceConfigurationId, setSelectedDeviceConfigurationId] =
    useState("");
  const [deviceRecords, setDeviceRecords] = useState<any>([]);

  const resetForm = () => {
    setSelectedApplicationId("");
    setSelectedDeviceConfigurationId("");
    setSelectedFile({});
    setDeviceRecords([]);
  };

  const { enqueueSnackbar } = useSnackbar();
  const { trigger: bulkUpdate, status } = useApi(
    `/applications/${selectedApplicationId}/devices/bulk`,
    {
      method: "PUT",
      deferred: true,
      responseDataFormatter: (responseData: any) => {
        try {
          const {
            data = "An error occured while processing request",
            message = "error",
          } = responseData || {};
          const variant = message.toLowerCase();
          if (data) {
            enqueueSnackbar(data, {
              variant,
            });
          }
          if (variant === "success") {
            resetForm();
          }
        } catch {
          enqueueSnackbar("An error occured while processing request", {
            variant: "error",
          });
        }
      },
      //   mock: {
      //     fetcher: (data: any) => {
      //       console.log({ data });
      //     },
      //   },
    }
  );
  const classes = useStyles();

  const onUpload = (files: any) => {
    fileReader(files[0], (fileData: any) => {
      try {
        const sanitizedData = sanitizeFileData(fileData);
        validateData(sanitizedData);
        const deviceData = extractData(sanitizedData);
        setDeviceRecords(deviceData);
        setSelectedFile(files[0]);
      } catch (error) {
        const errorMessage = readError(error);
        enqueueSnackbar(errorMessage, { variant: "error" });
      }
    });
  };

  return (
    <InputContextProvider applicationId={selectedApplicationId}>
      <LinearProgressBar show={status.pending} />
      <Grid className={classes.wrapper}>
        <Grid container>
          <Grid item xs={8}>
            <FileUploader
              onError={(error: string) =>
                enqueueSnackbar(error, {
                  variant: "error",
                })
              }
              onUpload={onUpload}
              selectedFile={selectedFile}
              onRemove={() => {
                setSelectedFile({});
                setDeviceRecords([]);
              }}
            />
          </Grid>
          <Grid
            container
            xs={4}
            direction="column"
            alignItems="flex-end"
            justifyContent="space-between"
            style={{ padding: "8px 16px 12px 0" }}
          >
            <SampleCSVDownloader />
            <BulkUpdateStatusChecker />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6}>
            {applications && applications.length > 0 && (
              <DropDown
                label="Application *"
                options={applications.map(
                  ({
                    application_id: value,
                    application_name: label,
                  }: any) => ({ value, label })
                )}
                value={selectedApplicationId}
                onChange={(value: string) => setSelectedApplicationId(value)}
              />
            )}
          </Grid>
          <DeviceConfigurationDropDown
            value={selectedDeviceConfigurationId}
            onChange={(value: any) => setSelectedDeviceConfigurationId(value)}
          />
        </Grid>
        <Grid
          container
          justifyContent="space-between"
          className={classes.buttonHolder}
        >
          <CustomButton variant="outlined-blue" onClick={() => returnHome()}>
            Cancel
          </CustomButton>

          <SubmitButton
            disabled={
              deviceRecords.length < 1 ||
              !selectedDeviceConfigurationId ||
              status.pending
            }
            onClick={() => {
              bulkUpdate({
                devices: deviceRecords,
                common_metadata: {
                  device_config_id: selectedDeviceConfigurationId,
                },
              });
            }}
            style={{ height: "100%" }}
          >
            Update
          </SubmitButton>
        </Grid>
      </Grid>
    </InputContextProvider>
  );
};

//TODO: ApplicationContext should be elevated to Application Root. Currently this is duplicated in all pages.
const Wrapper = (props: any) => {
  return (
    <ApplicationContextProvider>
      <BulkUpdate {...props} />
    </ApplicationContextProvider>
  );
};

export default Wrapper;
