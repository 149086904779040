import React, { useState } from "react";

import {
  CustomButton,
  Modal,
  LoadingIndicator,
} from "../../../../../UiComponents";
import useApi from "../../../../../../hooks/useApi";
// import { mockApi } from "./developerTools";
import { useApplicationContext } from "../../../../../../contexts/ApplicationContext";
import BulkUpdateStatus from "./BulkUpdateStatus";
import { useSnackbar } from "notistack";

/*Note: We are passing the application_id from context. So this will 
always come from the outer application selector, which is not visible
in ManageDevicePage. Status check is done per user.
*/
const BulkUpdateStatusChecker: React.FC<any> = ({ applicationId }) => {
  const { selectedApplication } = useApplicationContext();
  const [showStatusModal, setShowStatusModal] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const {
    data: bulkUpdateStatus,
    status,
    trigger: checkBulkupdateStatus,
  } = useApi(`/applications/${selectedApplication.application_id}/bulk-op`, {
    method: "GET",
    deferred: true,
    data: [],
    responseDataFormatter: (data) => {
      setShowStatusModal(true);
      return data;
    },
    onError: () => {
      enqueueSnackbar("An error occured while reading response", {
        variant: "error",
      });
    },
    // mock: { fetcher: mockApi },
  });

  return (
    <>
      {status.pending && <LoadingIndicator />}
      {bulkUpdateStatus && showStatusModal && (
        <Modal
          title={"Bulk Update Status"}
          open={true}
          onClose={() => setShowStatusModal(false)}
        >
          <BulkUpdateStatus bulkUpdateStatus={bulkUpdateStatus} />
        </Modal>
      )}
      <CustomButton
        variant="outlined-blue"
        onClick={() => checkBulkupdateStatus()}
        style={{ margin: "0" }}
      >
        Status Check
      </CustomButton>
    </>
  );
};

export default BulkUpdateStatusChecker;
